.footer_icon:hover {
    color: #0E53A4;
}

/* .links h1 {
    text-align: center;
    justify-items: center;
} */

.microsoft img {
    /* height: 80% !important; */
    width: 90%;
}